import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  CanActivateRouteGuard as AuthGuard
} from './configs/can-activate-route.guard';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ProfileComponent } from './views/profile/profile.component';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { UserDetailComponent } from './views/user-management/user-detail/user-detail.component';
import { QuestionManagementComponent } from './views/question-management/question-management.component';
import { AddQuestionComponent } from './views/question-management/add-question/add-question.component';
import { EditQuestionComponent } from './views/question-management/edit-question/edit-question.component';
import { AddChildQuestionComponent } from './views/question-management/add-child-question/add-child-question.component';
import { EditChildQuestionComponent } from './views/question-management/edit-child-question/edit-child-question.component';
import { ContentManagementComponent } from './views/content-management/content-management.component';
import { OrientationManagementComponent } from './views/orientation-management/orientation-management.component';
import { AddOrientationComponent } from './views/orientation-management/add-orientation/add-orientation.component';
import { EditOrientationComponent } from './views/orientation-management/edit-orientation/edit-orientation.component';
import { ProfessionManagementComponent } from './views/profession-management/profession-management.component';
import { AddProfessionComponent } from './views/profession-management/add-profession/add-profession.component';
import { EditProfessionComponent } from './views/profession-management/edit-profession/edit-profession.component';
import { InterestManagementComponent } from './views/interest-management/interest-management.component';
import { AddInterestComponent } from './views/interest-management/add-interest/add-interest.component';
import { EditInterestComponent } from './views/interest-management/edit-interest/edit-interest.component';
import { FeedbackManagementComponent } from './views/feedback-management/feedback-management.component';
import { NotificationManagementComponent } from './views/notification-management/notification-management.component';


import { EventManagementComponent } from './views/events-management/event-management.component';
import { EventDetailComponent } from './views/events-management/event-detail/event-detail.component';
import { EventParticipantComponent } from './views/events-management/event-detail/event-participants/event-participant.component';


import { ReportManagementComponent } from './views/report-management/report-management.component';
import { ReportUserDetailComponent } from './views/report-management/report-user-detail/report-user-detail.component';
import { SubscriptionManagementComponent } from './views/subscription-management/subscription-management.component';
import { SubscriptionDetailComponent } from './views/subscription-management/subscription-detail/subscription-detail.component';
import { EventRequestComponent } from './views/events-management/event-detail/event-requests/event-request.component';
import { EventMediaComponent } from './views/events-management/event-detail/event-media/event-media.component';



export const routes: Routes = [
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full', },
  // { path: '404', component: P404Component, data: { title: 'Page 404' } },
  // { path: '500', component: P500Component, data: { title: 'Page 500' } },
  // { path: 'login', component: LoginComponent, data: { title: 'Login Page' } },
  // { path: 'register', component: RegisterComponent, data: { title: 'Register Page' } },
  // { path: '', component: DefaultLayoutComponent, data: { title: 'Home' },
  //   children: [
  //     { path: 'dashboard', component: DashboardComponent },
  //     { path: 'base', loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule) },
  //     { path: 'buttons', loadChildren: () => import('./views/buttons/buttons.module').then(m => m.ButtonsModule) },
  //     { path: 'charts', loadChildren: () => import('./views/chartjs/chartjs.module').then(m => m.ChartJSModule) }, 
  //     { path: 'dashboard',  loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule) },
  //     { path: 'icons', loadChildren: () => import('./views/icons/icons.module').then(m => m.IconsModule) },
  //     { path: 'notifications', loadChildren: () => import('./views/notifications/notifications.module').then(m => m.NotificationsModule) },
  //     { path: 'theme', loadChildren: () => import('./views/theme/theme.module').then(m => m.ThemeModule) },
  //     { path: 'widgets', loadChildren: () => import('./views/widgets/widgets.module').then(m => m.WidgetsModule) }
  //   ]
  // },
  // { path: '**', component: P404Component }

  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'register', component: RegisterComponent, data: { title: 'Register Page' } },
  { path: '', component: DefaultLayoutComponent, data: { title: 'Home' },
    children: [
      { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],  data: { title: 'Dashboard'} },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { title: 'Profile'}  },
      { path: 'user-management', component: UserManagementComponent, canActivate: [AuthGuard], data: { title: 'User Management'}  },
      { path: 'user-management/user-detail/:id', component: UserDetailComponent, canActivate: [AuthGuard], data: { title: 'User Detail'}  },

      { path: 'event-management', component: EventManagementComponent, canActivate: [AuthGuard], data: { title: 'Event Management'}  },
      { path: 'event-management/event-detail/:id', component: EventDetailComponent, canActivate: [AuthGuard], data: { title: 'Event Detail'}  },
      { path: 'event-participant/:id', component: EventParticipantComponent, canActivate: [AuthGuard], data: { title: 'Event Detail'}  },
      { path: 'event-request/:id', component: EventRequestComponent, canActivate: [AuthGuard], data: { title: 'Event Detail'}  },
      { path: 'event-media/:id', component: EventMediaComponent, canActivate: [AuthGuard], data: { title: 'Event Media'}  },
     

      { path: 'question-management', component: QuestionManagementComponent, canActivate: [AuthGuard], data: { title: 'Question Management'}  },
      { path: 'question-management/add-question', component: AddQuestionComponent, canActivate: [AuthGuard], data: { title: 'Add Question'}  },
      { path: 'question-management/edit-question/:id', component: EditQuestionComponent, canActivate: [AuthGuard], data: { title: 'Edit Question'}  },
      { path: 'question-management/add-child-question/:id', component: AddChildQuestionComponent, canActivate: [AuthGuard], data: { title: 'Add Child Question'}  },
      { path: 'question-management/edit-child-question/:id/:id2', component: EditChildQuestionComponent, canActivate: [AuthGuard], data: { title: 'Edit Child Question'}  },

      { path: 'orientation-management', component: OrientationManagementComponent, canActivate: [AuthGuard], data: { title: 'Orientation Managemnet'} },
      { path: 'orientation-management/add-orientation', component: AddOrientationComponent, canActivate: [AuthGuard], data: { title: 'Add Orientation'}  },
      { path: 'orientation-management/edit-orientation/:id', component: EditOrientationComponent, canActivate: [AuthGuard], data: { title: 'Edit Orientation'}  },
      { path: 'profession-management', component: ProfessionManagementComponent, canActivate: [AuthGuard], data: { title: 'Profession Managemnet'} },
      { path: 'profession-management/add-profession', component: AddProfessionComponent, canActivate: [AuthGuard], data: { title: 'Add Profession'}  },
      { path: 'profession-management/edit-profession/:id', component: EditProfessionComponent, canActivate: [AuthGuard], data: { title: 'Edit Profession'}  },
      { path: 'interest-management', component: InterestManagementComponent, canActivate: [AuthGuard], data: { title: 'Interest Managemnet'} },
      { path: 'interest-management/add-interest', component: AddInterestComponent, canActivate: [AuthGuard], data: { title: 'Add Interest'}  },
      { path: 'interest-management/edit-interest/:id', component: EditInterestComponent, canActivate: [AuthGuard], data: { title: 'Edit Interest'}  },

      { path: 'feedback-management', component: FeedbackManagementComponent, canActivate: [AuthGuard], data: { title: 'Feedback Managemnet'} },
      { path: 'notification-management', component: NotificationManagementComponent, canActivate: [AuthGuard], data: { title: 'Notification Managemnet'} },

      { path: 'subscription-management', component: SubscriptionManagementComponent, canActivate: [AuthGuard], data: { title: 'Subscription Managemnet'} },
      { path: 'subscription-management/subscription-detail/:id', component: SubscriptionDetailComponent, canActivate: [AuthGuard], data: { title: 'Subscription Detail'}  },

      { path: 'report-management', component: ReportManagementComponent, canActivate: [AuthGuard], data: { title: 'Reported User Managemnet'} },
      { path: 'report-management/report-user-detail/:id', component: ReportUserDetailComponent, canActivate: [AuthGuard], data: { title: 'Reported User Detail'}  },

      // { path: 'user_management', component: UserManagementComponent, canActivate: [AuthGuard] },
      // { path: 'user_management/user_detail/:id', component: UserDetailComponent, canActivate: [AuthGuard] },

      // { path: 'hotel_management', component: HotelManagementComponent, canActivate: [AuthGuard] },
      // { path: 'hotel_management/hotel_detail/:id', component: HotelDetailComponent, canActivate: [AuthGuard] },
      // { path: 'hotel_management/add_hotel', component: AddHotelComponent, canActivate: [AuthGuard] },
      // { path: 'hotel_management/edit_hotel/:id', component: EditHotelComponent, canActivate: [AuthGuard] },
   
      // { path: 'booking_management', component: BookingManagementComponent, canActivate: [AuthGuard] },
      // { path: 'booking_management/booking_detail/:id', component: BookingDetailComponent, canActivate: [AuthGuard] },

      // { path: 'change_password', component: ChangePasswordComponent, canActivate: [AuthGuard] },


      { path: 'terms-and-condition', component: ContentManagementComponent, canActivate: [AuthGuard], data: { title: 'Terms & Condition'} },
      { path: 'privacy-policy', component: ContentManagementComponent, canActivate: [AuthGuard], data: { title: 'Privacy Policy'} },
      { path: 'about-us', component: ContentManagementComponent, canActivate: [AuthGuard], data: { title: 'About Us'} },
      { path: 'disclaimer', component: ContentManagementComponent, canActivate: [AuthGuard], data: { title: 'Disclaimer'}  },
      // { path: 'faq', component: ContentManagementComponent, canActivate: [AuthGuard] },
      { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    ]
  },

  { path: '', redirectTo: '/dashboard', pathMatch: 'full'  },
  { path: '**', component: P404Component }

];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
