import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';


@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss']
})
export class EditQuestionComponent implements OnInit {
  questionForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  public question_id= '';
  public questiontitle= '';
  public ques= '';


  public title = 'Edit Question';
  private readonly notifier: NotifierService;

 constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {

     this.questionForm = this.formBuilder.group({
            questiontitle: ['', [Validators.required]],
            ques: ['', [Validators.required]]
          });

    this.activatedRoute.params.subscribe(params => {
      this.question_id = params['id'];
      this.getData()
    })
  }


  getData() {
    // stop here if form is invalid 
    let api_url = 'admin/question_detail?_id=' + this.question_id
    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.data[0];
          this.questiontitle=  this.responseData.title
          this.questionForm.patchValue({
              questiontitle:  this.responseData.title,
              ques:  this.responseData.ques
        
            }) 

        } else {
          if (res) {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        }
      },
      (err) => {

      }
    );
  }
  get f() { return this.questionForm.controls; }


  submit(){
    this.submitted = true;
    if (this.questionForm.invalid) {
      return;
    } else {

      let api_url = 'admin/add_question';

      var formData = new FormData();
      formData.append('title', this.questionForm.value.questiontitle)
      formData.append('ques', this.questionForm.value.ques)
      formData.append('_id', this.question_id)

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
           //this.questionForm.reset();
          if (res.code == 200) {
           
            this.notifier.notify('success', `Success:` + res.message)
            this.router.navigate(['/question-management'])
          } else {
            this.notifier.notify('error', res.message)
          }
        },
        (err) => {
           this.notifier.notify('error', err)
        }
      );
    }
  }

  


}
