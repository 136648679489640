import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: string = '';
  public fileImgUrl: string = '';

  public title = 'Update Profile';
  private readonly notifier: NotifierService;


  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService
  ) {
     this.notifier = notifierService
  }

  ngOnInit(): void {

    this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    // this.fileUrl =  this.userDetails.profileImages != ''  &&  this.userDetails.profileImages!='default_user.png' ? this.api.IMAGE_BASE_URL +'/images/'+this.userDetails.profileImages : "assets/img/no_image.png";
   

    this.profileForm = this.formBuilder.group({
              email: [this.userDetails.email, [Validators.required, Validators.compose([
                Validators.required,
                Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
              ])]],
              password: [''],
              new_password: [''],
              avatar: [this.fileUrl],
              fullName: [this.userDetails.fullName, [Validators.required]],
            });
    this.userDetail();
  }
  get f() { return this.profileForm.controls; }

  submit(){
    this.submitted = true;
    if (this.profileForm.invalid) {
      return;
    } else {

      let api_url = 'admin/update_profile';

      var formData = new FormData();
      formData.append('email', this.profileForm.value.email)
      formData.append('fullName', this.profileForm.value.fullName)
      formData.append('profilePic', this.profileForm.get('avatar').value.name == undefined ? '' : this.profileForm.value.avatar)
      formData.append('currentPassword', this.profileForm.controls.password.value)
      formData.append('password', this.profileForm.controls.new_password.value ? this.profileForm.controls.new_password.value : '')

      if (this.profileForm.value.password && this.profileForm.value.password.trim() != '') {
        if (this.profileForm.value.new_password && this.profileForm.value.new_password.trim() != '') {
          this.api.postRequest(api_url, formData).then(
            (res) => {
              this.submitted = false;
               //this.profileForm.reset();
              if (res.code == 200) {
                this.api.setUserLoggedIn(res.result.accessToken, res.result)
                this.notifier.notify('success', `Success: Profile updated successfully`)
                this.router.navigate(['/dashboard'])
              } else {
                this.notifier.notify('error', res.message)
              }
            },
            (err) => {
               this.notifier.notify('error', err)
            }
          );
        } else {
           this.notifier.notify('error', `Error: Please enter new password.`);
        }
      }else if (this.profileForm.value.new_password && this.profileForm.value.new_password.trim() != '') {
        if (this.profileForm.value.password && this.profileForm.value.password.trim() != '') {
          this.api.postRequest(api_url, formData).then(
            (res) => {
              this.submitted = false;
               //this.profileForm.reset();
              if (res.code == 200) {
                this.api.setUserLoggedIn(res.result.accessToken, res.result)
                this.notifier.notify('success', `Success: Profile updated successfully`)
                this.router.navigate(['/dashboard'])
              } else {
                this.notifier.notify('error', res.message)
              }
            },
            (err) => {
               this.notifier.notify('error', err)
            }
          );
        } else {
          this.notifier.notify('error', `Error: Please enter current password.`);
        }
      }else{
        this.api.postRequest(api_url, formData).then(
          (res) => {
            this.submitted = false;
             //this.profileForm.reset();
            if (res.code == 200) {
              this.api.setUserLoggedIn(res.result.accessToken, res.result)
              this.notifier.notify('success', `Success: Profile updated successfully`)
              this.router.navigate(['/dashboard'])
            } else {
              this.notifier.notify('error', res.message)
            }
          },
          (err) => {
             this.notifier.notify('error', err)
          }
        );
      }

      
    }
  }

  showPreview(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.profileForm.patchValue({
      avatar: file
    });
    this.profileForm.get('avatar').updateValueAndValidity()

    // File Preview
    const reader = new FileReader();
    if (file.type.indexOf('image') > -1) {
      // this.format = 'image';
    } else if (file.type.indexOf('video') > -1) {
      // this.format = 'video';
      this.profileForm.patchValue({
        isvideo: true
      });
      this.profileForm.get('isvideo').updateValueAndValidity()

    }
    reader.onload = () => {
      this.fileUrl = reader.result as string;
    }
    reader.readAsDataURL(file)
  }
 
  userDetail(){
    let api_url = 'admin/user_detail?_id='+this.userDetails._id

    this.api.getRequest(api_url, {}).then(
      (res) => { 
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result; 
       
          this.fileUrl = this.fileImgUrl=  res.data[0].profile_pic != '' && res.data[0].profile_pic != undefined ? this.api.IMAGE_BASE_URL +'/images/'+res.data[0].profile_pic : "assets/img/no_image.png";
   
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

}
