import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';

import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service"

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public responseData: any = {};
  userDetails: any = {};
  public fileImgUrl: string = '';
  public fileUrl: string='';
  year;


  constructor(
    private router: Router,
    public api: ApiCallingService,
  ) {
  }
  ngOnInit() {
    this.userDetails = JSON.parse(localStorage.getItem('userDetails'))
    var d = new Date();
    this.year = d.getFullYear();
    this.userDetail();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

 logout() {
    var api_url = 'admin/logout'
    this.api.postRequest(api_url, {}).then(
      (res) => { 
        this.router.navigate(['/login']);
        if (res.code == 200) {
          localStorage.clear();
        } else {

        }
      },
      (err) => {
      }
    );

  }
  
  userDetail(){
    let api_url = 'admin/user_detail?_id='+this.userDetails._id

    this.api.getRequest(api_url, {}).then(
      (res) => { 
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result; 
         
          this.fileUrl = this.fileImgUrl=  res.data[0].profile_pic != '' && res.data[0].profile_pic != undefined  ? this.api.IMAGE_BASE_URL +'/images/'+res.data[0].profile_pic : "assets/img/no_image.png";
           } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }


}
