// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // SERVER_URL: "http://52.45.16.118:5009/v1/",
  SERVER_URL: "https://apiwetango.wetangoapp.com/v1/",
  EXPRESS_SERVER_URL: "https://v2.wetangoapp.com/v2/",
  // EXPRESS_SERVER_URL: "http://localhost:4000/v2/",
  IMG_BASE_URL: "https://apiwetango.wetangoapp.com/",
  STATUS_CODE: {
  
    SUCCESS: {
      CODE: 200,
      MESSAGE: 'SUCCESS'
    },
    FAILURE: {
      CODE: 201,
      MESSAGE: 'ERROR'
    },
    SESSION_EXPIRE: {
      CODE: 203,
      MESSAGE: 'SUCCESS'
    },
    DATA_NOT_FOUND: {
      CODE: 202,
      MESSAGE: 'SUCCESS'
    },
    INTERNEL_SERVER_ERROR: {
      CODE: 500,
      MESSAGE: 'ERROR'
    },
    API_NOT_FOUND: {
      CODE: 404,
      MESSAGE: 'ERROR'
    },
  }
};
