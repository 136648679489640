import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html',
  styleUrls: ['./subscription-detail.component.scss']
})
export class SubscriptionDetailComponent implements OnInit {

  questionForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  responseBoost: any = [];
  public search = '';

  public user_id= '';
  public fullName= '';
  public email= '';
  public mobileNumber= '';
  public purchaseType= '';
  public subscription_period= '';
  public title = 'Subscription Detail';

   // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;


  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {

     this.questionForm = this.formBuilder.group({
            fullName: ['', [Validators.required]],
            email: ['', [Validators.required]],
            mobileNumber: ['', [Validators.required]],
            purchaseType: ['', [Validators.required]],
            subscription_period: ['', [Validators.required]],
          });

    this.activatedRoute.params.subscribe(params => {
      this.user_id = params['id'];
      this.getData()
    })
  }

  getData(){
    
    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'admin/subscription_detail?_id=' + this.user_id ; //+ '&skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search;

    this.api.getRequest(api_url, {}).then(
      (res) => { console.log("&*cjshdjcsdc", res)
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result
          this.totalRecords = res.result.totalCount;
          this.responseBoost = res.result.boostLike;
          

           this.questionForm.patchValue({
              fullName:  this.responseData.fullName,
              email:  this.responseData.email,
              mobileNumber:  this.responseData.mobileNumber,
              purchaseType:  this.responseData.purchaseType,
              subscription_period: this.responseData.subscription_period+ ' Month',
            }) 

        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {

      }
    );

  }
  get f() { return this.questionForm.controls; }

  submit(){
    this.submitted = true;
    if (this.questionForm.invalid) {
      return;
    } else {

    }
  }

  activeInactiveQuestion(val, status) {

    
  }
}
