import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-add-child-question',
  templateUrl: './add-child-question.component.html',
  styleUrls: ['./add-child-question.component.scss']
})
export class AddChildQuestionComponent implements OnInit {
  questionForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  responseQData: any = [];
  public search = '';

  public question_id= '';
  public questiontitle= '';
  public ques= '';
  public title = 'Add Child Question';

   // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;


  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {

     this.questionForm = this.formBuilder.group({
            questiontitle: ['', [Validators.required]],
            ques: ['', [Validators.required]]
          });

    this.activatedRoute.params.subscribe(params => {
      this.question_id = params['id'];
      this.getData()
      //this.getQuestion();
    })
  }

  // getQuestion(){

   
  //   let api_url = 'admin/question_detail?_id=' + this.question_id;

  //   this.api.getRequest(api_url, {}).then(
  //     (res) => {
  //       if (res != undefined && (res.code == 200 || res.code == 202)) {
  //         this.responseQData = res.data[0]

  //         this.questionForm.patchValue({
  //           questiontitle:  this.responseQData.title,
  //           ques:  this.responseQData.ques
      
  //         }) 
  //       } else {
  //         if (res) {
  //           alert(res.message)

  //         }
  //       }
  //     },
  //     (err) => {

  //     }
  //   );


  // }
  getData(){
    
    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'admin/get_child_question?_id=' + this.question_id + '&skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search+ '&role=USER';

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseData = res.data

          console.log("############", res.data)

        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {

      }
    );

  }
  get f() { return this.questionForm.controls; }

  submit(){
    this.submitted = true;
    if (this.questionForm.invalid) {
      return;
    } else {

      let api_url = 'admin/add_child_question';

      var formData = new FormData();
      formData.append('title', this.questionForm.value.questiontitle)
      formData.append('ques', this.questionForm.value.ques)
      formData.append('parentId', this.question_id)

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
           //this.questionForm.reset();
          if (res.code == 200) {
           this.questionForm.reset();
            this.notifier.notify('success', `Success:` + res.message)
             this.getData()
          } else {
            this.notifier.notify('error', res.message)
          }
        },
        (err) => {
           this.notifier.notify('error', err)
        }
      );
    }
  }

  activeInactiveQuestion(val, status) {

    Swal.fire({
      text: status ? 'Are you sure, You want to inactive this question ?' : 'Are you sure, You want to active this question?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      cancelButtonText: 'Cancel'
    }).then((result) => {

      if (result.value) {

        this.api.postRequest('admin/activeInactiveQuestion', {
          question_id: val,
          block: status ? true : false
        }).then(
          (res) => {

            if (res && res.code == 200) {
              Swal.fire(
                'Success!',
                status ? 'Question marked inactive successfully.' : 'Question marked active successfully.',
                'success'
              )

              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == val.toString()) {
                  this.responseData[i].active = status;
                }
              }

            } else {
              Swal.fire(
                'Something Went Wrong.',
                'Please try again after some time.',
                'error'
              )
            }
          },
          (err) => {
            Swal.fire(
              'Something Went Wrong.',
              'Please try again after some time.',
              'error'
            )
          }
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }
}
