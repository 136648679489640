import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  { name: 'Dashboard', url: '/dashboard', icon: 'icon-speedometer',badge: { variant: 'info', text: 'NEW'} },

  {
    name: 'User Management',url: '/user-management',icon: 'fa fa-user',
    children: [
      { name: 'Users', url: '/user-management',icon: 'fa fa-user' },
      { name: 'Reported Users', url: '/report-management',icon: 'fa fa-user' },
    ]
  },

  { name: 'Events Management', url: '/event-management', icon: 'icon-event'},
  { name: 'Question Management', url: '/question-management', icon: 'icon-question'},
  { name: 'FeedBack Management', url: '/feedback-management', icon: 'fa fa-comments-o'},
  { name: 'Notification Management', url: '/notification-management', icon: 'fa fa-bell'},
  { name: 'Subscription Management', url: '/subscription-management', icon: 'fa fa-rocket'},
  // {
  //   name: 'Category Management',url: '/category-management',icon: 'fa fa-list-alt',
  //   children: [
  //     { name: 'Orientation', url: '/orientation-management',icon: 'fa fa-gavel' },
  //     { name: 'Profession', url: '/profession-management',icon: 'fa fa-gavel' },
  //     { name: 'Interest', url: '/interest-management',icon: 'fa fa-gavel' },
  //   ]
  // },

  {
    name: 'Content Management',url: '/content-management',icon: 'fa fa-file',
    children: [
      { name: 'Privacy Policy', url: '/privacy-policy',icon: 'fa fa-gavel' },
      { name: 'Terms & Conditions',url: '/terms-and-condition',  icon: 'icon-star' },
      { name: 'Disclaimer', url: '/disclaimer', icon: 'fa fa-gavel' },
      { name: 'About Us',  url: '/about-us',icon: 'fa fa-question'},
    ]
  },
  // { name: 'Buttons', url: '/buttons', con: 'icon-cursor',
  //   children: [
  //     { name: 'Buttons', url: '/buttons/buttons', icon: 'icon-cursor' },
  //     { name: 'Dropdowns', url: '/buttons/dropdowns', icon: 'icon-cursor' },
  //     { name: 'Brand Buttons', url: '/buttons/brand-buttons', icon: 'icon-cursor' }
  //   ]
  // },

  // { name: 'Question management', url: '/question-management', icon: 'icon-question', 
  //    children: [
  //      { name: 'Manage Question', url: '/question-management', icon: 'icon-cursor' },
  //      { name: 'Add Question', url: '/add-question', icon: 'icon-cursor' },
  //    ]
  // },

  // { title: true, name: 'Theme'  },
  // { name: 'Colors',  url: '/theme/colors',   icon: 'icon-drop' },
  // { name: 'Typography', url: '/theme/typography', icon: 'icon-pencil'  },
  // { title: true, name: 'Components' },
  // {
  //   name: 'Base',  url: '/base',  icon: 'icon-puzzle',
  //   children: [
  //     { name: 'Cards', url: '/base/cards', icon: 'icon-puzzle' },
  //     { name: 'Carousels', url: '/base/carousels', icon: 'icon-puzzle' },
  //     { name: 'Collapses', url: '/base/collapses', icon: 'icon-puzzle' },
  //     { name: 'Forms', url: '/base/forms', icon: 'icon-puzzle'},
  //     { name: 'Navbars', url: '/base/navbars', icon: 'icon-puzzle' },
  //     { name: 'Pagination', url: '/base/paginations', icon: 'icon-puzzle' },
  //     { name: 'Popovers', url: '/base/popovers', icon: 'icon-puzzle' },
  //     { name: 'Progress', url: '/base/progress', icon: 'icon-puzzle' },
  //     { name: 'Switches', url: '/base/switches', icon: 'icon-puzzle' },
  //     { name: 'Tables', url: '/base/tables', icon: 'icon-puzzle' },
  //     { name: 'Tabs', url: '/base/tabs', icon: 'icon-puzzle' },
  //     { name: 'Tooltips', url: '/base/tooltips', icon: 'icon-puzzle' }
  //   ]
  // },
  // // { name: 'Buttons', url: '/buttons', con: 'icon-cursor',
  // //   children: [
  // //     { name: 'Buttons', url: '/buttons/buttons', icon: 'icon-cursor' },
  // //     { name: 'Dropdowns', url: '/buttons/dropdowns', icon: 'icon-cursor' },
  // //     { name: 'Brand Buttons', url: '/buttons/brand-buttons', icon: 'icon-cursor' }
  // //   ]
  // // },
  // { name: 'Charts', url: '/charts', icon: 'icon-pie-chart' },
  // { name: 'Icons', url: '/icons', icon: 'icon-star',
  //   children: [
  //     { name: 'CoreUI Icons', url: '/icons/coreui-icons', icon: 'icon-star', badge: { variant: 'success', text: 'NEW' } },
  //     { name: 'Flags', url: '/icons/flags', icon: 'icon-star' },
  //     { name: 'Font Awesome', url: '/icons/font-awesome', icon: 'icon-star',badge: { variant: 'secondary', text: '4.7' } },
  //     { name: 'Simple Line Icons', url: '/icons/simple-line-icons', icon: 'icon-star'}
  //   ]
  // },
  // { name: 'Notifications', url: '/notifications', icon: 'icon-bell',
  //   children: [
  //     { name: 'Alerts', url: '/notifications/alerts', icon: 'icon-bell' },
  //     { name: 'Badges', url: '/notifications/badges', icon: 'icon-bell' },
  //     { name: 'Modals', url: '/notifications/modals', icon: 'icon-bell' }
  //   ]
  // },
  // { name: 'Widgets', url: '/widgets', icon: 'icon-calculator', badge: { variant: 'info', text: 'NEW' } },
  // { divider: true },
  // { title: true, name: 'Extras',},
  // { name: 'Pages', url: '/pages', icon: 'icon-star',
  //   children: [
  //     { name: 'Login', url: '/login', icon: 'icon-star' },
  //     { name: 'Register', url: '/register', icon: 'icon-star'},
  //     { name: 'Error 404', url: '/404', icon: 'icon-star' },
  //     { name: 'Error 500', url: '/500', icon: 'icon-star' }
  //   ]
  // },
  //{ name: 'Disabled', url: '/dashboard', icon: 'icon-ban', badge: { variant: 'secondary', text: 'NEW' }, attributes: { disabled: true },},
 
];
