import { Component, OnInit } from '@angular/core';
// import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
// import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  userDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: string = '';
  public fileImgUrl: string = '';
  private readonly notifier: NotifierService;


  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService
  ) {
     this.notifier = notifierService
  }
  
  ngOnInit(): void {
    // generate random values for mainChart
    this.getData();
   
  }
  getData() {
    let api_url = 'admin/dashboard'
    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.result; 

          console.log("@!!!!!!!!!!!!!!", this.responseData)
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }
}
