import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-notification-management',
  templateUrl: './notification-management.component.html',
  styleUrls: ['./notification-management.component.scss']
})
export class NotificationManagementComponent implements OnInit {

  notificationForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: string = '';
  public fileImgUrl: string = '';

  public heading = 'Notification Management';
  private readonly notifier: NotifierService;
  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService
  ) {
     this.notifier = notifierService
  }


  ngOnInit(): void {

    this.notificationForm = this.formBuilder.group({
              title: ['', [Validators.required]],
              message: ['', [Validators.required]],
              notificationType: ['', [Validators.required]],
            });

    this.notificationForm.patchValue({
      notificationType: 1,
    });


  }

  get f() { return this.notificationForm.controls; }


  submit(){

    console.log("#@@@@@@@@@@@@@",  this.notificationForm.controls)
    this.submitted = true;
    if (this.notificationForm.invalid) {
      return;
    } else {

      let api_url = 'admin/send_pushnotification';

      var formData = new FormData();
      formData.append('title', this.notificationForm.value.title)
      formData.append('message', this.notificationForm.value.message)
      formData.append('type', this.notificationForm.value.notificationType)

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
           //this.questionForm.reset();
          if (res.code == 200) {
           
            this.notifier.notify('success', `Success: Notification sent successfully`)
            this.router.navigate(['/notification-management'])
          } else {
            this.notifier.notify('error', res.message)
          }
        },
        (err) => {
           this.notifier.notify('error', err)
        }
      );
    }
  }

}
