import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-add-orientation',
  templateUrl: './add-orientation.component.html',
  styleUrls: ['./add-orientation.component.scss']
})
export class AddOrientationComponent implements OnInit {

  orientationForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public fileUrl: string = '';
  public fileImgUrl: string = '';

  public title = 'Add New Orientation';
  private readonly notifier: NotifierService;
  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService
  ) {
     this.notifier = notifierService
  }


  ngOnInit(): void {

    this.orientationForm = this.formBuilder.group({
              category_name: ['', [Validators.required]],
            });

  }

  get f() { return this.orientationForm.controls; }


  submit(){
    this.submitted = true;
    if (this.orientationForm.invalid) {
      return;
    } else {

      let api_url = 'admin/add_category';
      let orientation = 1;

      var formData = new FormData();
      formData.append('category_name', this.orientationForm.value.category_name)
      formData.append('type', '1')

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
           //this.questionForm.reset();
          if (res.code == 200) {
           
            this.notifier.notify('success', `Success: Orientation added successfully`)
            this.router.navigate(['/orientation-management'])
          } else {
            this.notifier.notify('error', res.message)
          }
        },
        (err) => {
           this.notifier.notify('error', err)
        }
      );
    }
  }

}
