import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public id: string = '';
  public fileUrl: string = '';
  public fileImgUrl: string = '';

  public title = 'User Detail';
  private readonly notifier: NotifierService;
  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService,
     private activatedRoute: ActivatedRoute

  ) {
     this.notifier = notifierService
  }

  ngOnInit(): void {

      this.activatedRoute.params.subscribe(params => {
        this.id = params['id'];
        this.userDetail()
      })
  }

  userDetail(){
    let api_url = 'admin/user_detail?_id='+this.id

    this.api.getRequest(api_url, {}).then(
      (res) => { 
        if (res != undefined && (res.code == 200 || res.code == 202)) {
           console.log("@!!!!!!!!!!!!!!!!",res.data[0])

          this.responseData = res.data[0]; 
       
          this.fileUrl = this.fileImgUrl=  this.responseData.profileImages != ''  && this.responseData.profileImages!='default_user.png' ? this.api.IMAGE_BASE_URL +'/images/'+this.responseData.profileImages : "assets/img/no_image.png";
   
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

}
