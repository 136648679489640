import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { IconModule, IconSetModule, IconSetService } from '@coreui/icons-angular';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

//services
import { CanActivateRouteGuard } from "./configs/can-activate-route.guard";
import { Interceptor } from './configs/interceptor';

// modules
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxPaginationModule} from 'ngx-pagination';
import { CKEditorModule } from 'ckeditor4-angular';
import { NotifierModule } from "angular-notifier";

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { ForgotPasswordComponent } from './views/forgot-password/forgot-password.component';
import { ProfileComponent } from './views/profile/profile.component';
import { UserManagementComponent } from './views/user-management/user-management.component';
import { UserDetailComponent } from './views/user-management/user-detail/user-detail.component';
import { QuestionManagementComponent } from './views/question-management/question-management.component';
import { AddQuestionComponent } from './views/question-management/add-question/add-question.component';
import { EditQuestionComponent } from './views/question-management/edit-question/edit-question.component';
import { AddChildQuestionComponent } from './views/question-management/add-child-question/add-child-question.component';
import { EditChildQuestionComponent } from './views/question-management/edit-child-question/edit-child-question.component';
import { ContentManagementComponent } from './views/content-management/content-management.component';
import { OrientationManagementComponent } from './views/orientation-management/orientation-management.component';
import { ReportManagementComponent } from './views/report-management/report-management.component';
import { ReportUserDetailComponent } from './views/report-management/report-user-detail/report-user-detail.component';
import { AddOrientationComponent } from './views/orientation-management/add-orientation/add-orientation.component';
import { EditOrientationComponent } from './views/orientation-management/edit-orientation/edit-orientation.component';
import { ProfessionManagementComponent } from './views/profession-management/profession-management.component';
import { AddProfessionComponent } from './views/profession-management/add-profession/add-profession.component';
import { EditProfessionComponent } from './views/profession-management/edit-profession/edit-profession.component';
import { InterestManagementComponent } from './views/interest-management/interest-management.component';
import { AddInterestComponent } from './views/interest-management/add-interest/add-interest.component';
import { EditInterestComponent } from './views/interest-management/edit-interest/edit-interest.component';
import { FeedbackManagementComponent } from './views/feedback-management/feedback-management.component';
import { NotificationManagementComponent } from './views/notification-management/notification-management.component';
import { SubscriptionManagementComponent } from './views/subscription-management/subscription-management.component';
import { SubscriptionDetailComponent } from './views/subscription-management/subscription-detail/subscription-detail.component';
import { EventManagementComponent } from './views/events-management/event-management.component';
import { EventDetailComponent } from './views/events-management/event-detail/event-detail.component';
import { EventParticipantComponent } from './views/events-management/event-detail/event-participants/event-participant.component';
import { StarRatingComponent } from './views/events-management/event-detail/start-rating.component';
import { EventRequestComponent } from './views/events-management/event-detail/event-requests/event-request.component';
import { EventMediaComponent } from './views/events-management/event-detail/event-media/event-media.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    IconModule,
    IconSetModule.forRoot(),
    NotifierModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule, 
    ReactiveFormsModule, 
    CKEditorModule,
    
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    UserManagementComponent,
    UserDetailComponent,
    EventDetailComponent,
    EventParticipantComponent,
    StarRatingComponent,
    EventRequestComponent,
    EventMediaComponent,
    QuestionManagementComponent,
    AddQuestionComponent,
    EditQuestionComponent,
    AddChildQuestionComponent,
    EditChildQuestionComponent,
    ContentManagementComponent,
    OrientationManagementComponent,
    ReportManagementComponent,
    ReportUserDetailComponent,
    AddOrientationComponent,
    EditOrientationComponent,
    ProfessionManagementComponent,
    AddProfessionComponent,
    EditProfessionComponent,
    InterestManagementComponent,
    AddInterestComponent,
    EditInterestComponent,
    FeedbackManagementComponent,
    NotificationManagementComponent,
    SubscriptionManagementComponent,
    SubscriptionDetailComponent,
    EventManagementComponent
  ],
  providers: [
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    IconSetService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    },
    CanActivateRouteGuard
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
