import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../configs/api-calling.service"
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-dashboard',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  forgotForm: FormGroup;
  submitted = false;
  private readonly notifier: NotifierService;


  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService
  ) {
     this.notifier = notifierService
  }


  ngOnInit(): void {

     this.api.restricted();
     this.forgotForm = this.formBuilder.group({

      email: ['', [Validators.required, Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])]]

    });

  }

  get f() { return this.forgotForm.controls; }

  forgotPassword(){

     this.submitted = true;
    // stop here if form is invalid 
    if (this.forgotForm.invalid) {
      return;
    } else {
      let api_url = 'admin/forgotPassword'

      let requestData = {
        "email": this.forgotForm.controls.email.value 
      }
       this.spinner.show();
      this.api.postRequest(api_url, requestData).then(
        (res:any) => {
          this.spinner.hide();
          if(res != undefined){
            if(res.code == 200){
              alert(res.message)
            this.router.navigate(['/login'])
            }else{
              alert(res.message)
            }
          }
        }).catch((err:any)=>{
            this.notifier.notify('error', err.message);
          
        });
    }

  }



}
