import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-orientation',
  templateUrl: './edit-orientation.component.html',
  styleUrls: ['./edit-orientation.component.scss']
})
export class EditOrientationComponent implements OnInit {

  categoryForm: FormGroup;
  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  public category_id= '';
  public category_name= '';


  public title = 'Edit Category';
  private readonly notifier: NotifierService;

 constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    private activatedRoute: ActivatedRoute,
    notifierService: NotifierService

  ) {
    this.notifier = notifierService;
  }

  ngOnInit(): void {

     this.categoryForm = this.formBuilder.group({
            category_name: ['', [Validators.required]],
          });

    this.activatedRoute.params.subscribe(params => {
      this.category_id = params['id'];
      this.getData()
    })
  }


  getData() {
    // stop here if form is invalid 
    let api_url = 'admin/category_detail?category_id=' + this.category_id
    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.responseData = res.data[0]; 
          this.categoryForm.patchValue({
              category_name:  this.responseData.category_name,
            }) 

        } else {
          if (res) {
            this.notifier.notify('error', `Error: ${res.message}`);
          }
        }
      },
      (err) => {

      }
    );
  }
  get f() { return this.categoryForm.controls; }


  submit(){
    this.submitted = true;
    if (this.categoryForm.invalid) {
      return;
    } else {

      let api_url = 'admin/add_category';

      var formData = new FormData();
      formData.append('category_name', this.categoryForm.value.category_name)
      formData.append('type', '1')
      formData.append('_id', this.category_id)

      this.api.postRequest(api_url, formData).then(
        (res) => {
          this.submitted = false;
           //this.questionForm.reset();
          if (res.code == 200) {
           
            this.notifier.notify('success', `Success: ` + res.message)
            this.router.navigate(['/orientation-management'])
          } else {
            this.notifier.notify('error', res.message)
          }
        },
        (err) => {
           this.notifier.notify('error', err)
        }
      );
    }
  }

  


}
