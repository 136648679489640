import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiCallingService } from '../../../../configs/api-calling.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-event-request',
  templateUrl: './event-request.component.html',
  styleUrls: ['./event-request.component.scss']
})
export class EventRequestComponent implements OnInit {

  public title = 'Event Requests';
  public responseData: any = [];
  public search = '';
  public id: string = '';
  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;
  currentDateInMilliseconds: number;
  private readonly notifier: NotifierService;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private router: Router,
    public api: ApiCallingService,
    notifierService: NotifierService,
    private activatedRoute: ActivatedRoute

  ) {
    this.notifier = notifierService;
    this.currentDateInMilliseconds = new Date().getTime();
  }


  ngOnInit(): void {

    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];

      this.getData();
    })
}

  pageChanged(val) {
    this.skip = this.itemsPerPage * val - this.itemsPerPage;
    this.getData()

  }

  getData() {

    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'admin/event_request?_id='+ this.id  +'&skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search;

    this.api.getRequestExpressServer(api_url, {}).then(
      (res) => {
        console.log(res," this ois ois knosbobbohob")
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalCount;
          this.responseData = res.data

        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {
console.log(err,'err')
      }
    );
  }

  deleteEvent(deleteId){
    Swal.fire({
      title: 'Are you sure, You want to delete this event ?',
      text: 'You will not be able to recover this event.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {

      if (result.value) {

        this.api.postRequestExpressServer('admin/deleteEvent', { delete_id: deleteId }).then(
          (res) => {
            if (res && res.code == 200) {
              Swal.fire(
                'Deleted!',
                'Event has been deleted.',
                'success'
              )
              for (var i in this.responseData) {
                if (this.responseData[i]._id.toString() == deleteId.toString()) {
                  this.responseData.splice(i, 1);
                }
              }

            } else {

              Swal.fire(
                'Something Went Wrong.',
                'Please try Again after some time.',
                'error'
              )
            }

          },
          (err) => {

            Swal.fire(
              'Something Went Wrong.',
              'Please try Again after some time.',
              'error'
            )
          }
        );

      } else if (result.dismiss === Swal.DismissReason.cancel) {

        Swal.fire(
          'Cancelled',
          'Your request has been cancelled',
          'error'
        )
      }
    })
  }

}
