// star-rating.component.ts
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-star-rating',
  template: `
    <div class="star-rating">
      <span *ngFor="let _ of starsArray; let i = index" class="star" [ngClass]="{'filled': i < rating}">
        ★
      </span>
    </div>
  `,
  styles: [`
    .star-rating {
      display: inline-block;
    }
    .star {
      color: #9e9c93;
      font-size: 20px;
      cursor: pointer;
    }
    .filled {
      color: #FFD700;
    }
  `]
})
export class StarRatingComponent {
  @Input() rating: number = 0;
  starsArray: any[] = new Array(5); // Assuming 5 stars
}
