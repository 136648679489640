import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";
import { ApiCallingService } from "../../configs/api-calling.service";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-content-management',
  templateUrl: './content-management.component.html',
  styleUrls: ['./content-management.component.scss']
})
export class ContentManagementComponent implements OnInit {
  requestData: any;
  notifier: any;
  content: string;
  type: number;
  htmlContent = '';
  page='';

  constructor(
    private formBuilder: FormBuilder, 
    private router: Router, 
    private spinner: NgxSpinnerService, 
    public api: ApiCallingService, 
    private notifierService: NotifierService
    ) {
    this.notifier = notifierService;
    
  }

  ngOnInit(): void {

    this.notifier.hideOldest();
    if (window.location.hash == '#/cancellation-policy') {
      this.type = 5
      this.page= 'Cancellation Policy'
      this.getData()
    } else if (window.location.hash == '#/privacy-policy') {
      this.type = 2
      this.page= 'privacy Policy'
      this.getData()
    } else if (window.location.hash == '#/about-us') {
      this.type = 3
      this.page= 'about us'
      this.getData()
    } else if (window.location.hash == '#/terms-and-condition') {
      this.type = 1
      this.page= 'terms-and-condition'
      this.getData()
    }else if (window.location.hash == '#/disclaimer') {
      this.type = 4
      this.page= 'disclaimer'
      this.getData()
    }
    else if (window.location.hash == '#/support') {
      this.type = 6
      this.page= 'support'
      this.getData()
    }
     else {
      console.log("No type")
    }


  }
  name: string = '';

  submit() {
    this.spinner.show()

    this.requestData = {
      "content": this.htmlContent,
      "type": this.type
    }

    this.api.postRequest('admin/add_content', this.requestData).then(
      (res) => {
        this.spinner.hide()
        if (res && res.code == 200) {

          this.notifier.notify('success', ' Success , Content updated successfully.');

        } else {

          this.notifier.hideOldest();
          this.notifier.notify('error', ' Whoops , ' + res.message);
        }
      },
      (err) => {
      }
    );
  }

  getData() {
    this.spinner.show()

    this.requestData = {
      "accessToken": localStorage.getItem('token'),
      "type": this.type
    }
    this.api.getRequest('admin/content_management?type=' + this.type, this.requestData).then(
      (res) => {
        this.spinner.hide()
        if (res && res.code == 200) {
          if (res.result != null) {
            this.htmlContent = res.result.content;
          }
        } else {

          this.notifier.hideOldest();
          this.notifier.notify('error', ' Whoops , ' + res.message);
        }
      },
      (err) => {
      }
    );
  }
}
