import { Component, OnInit, Input  } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  submitted = false;
  eventDetails: any = {};
  responseData: any = [];
  fileToUpload: File = null;
  public id: string = '';
  public fileUrl: string = '';
  public fileImgUrl: string = '';
  public currentImageIndex = 0;
  public currentMediaIndex = 0;
  public title = 'Event Detail';
  public pics: any = [];
  private readonly notifier: NotifierService;
  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService,
     private activatedRoute: ActivatedRoute

  ) {
     this.notifier = notifierService
  }



prevImage() {

    this.currentImageIndex = (this.currentImageIndex - 1 + this.responseData.pics.length) % this.responseData.pics.length;

}


nextImage() {
    this.currentImageIndex = (this.currentImageIndex + 1) % this.responseData.pics.length;

}
prevMedia() {

    this.currentMediaIndex = (this.currentMediaIndex - 1 + this.responseData.eventMedia.length) % this.responseData.eventMedia.length;

}


nextMedia() {
    this.currentMediaIndex = (this.currentMediaIndex + 1) % this.responseData.eventMedia.length;

}

  ngOnInit(): void {

      this.activatedRoute.params.subscribe(params => {
        this.id = params['id'];
        this.eventDetail()
      })
  }

  eventDetail(){
    let api_url = 'admin/event_detail?_id='+this.id

    this.api.getRequestExpressServer(api_url, {}).then(
      (res) => { 
        if (res != undefined && (res.code == 200 || res.code == 202)) {
           console.log("@!!!!!!!!!!!!!!!!",res.data[0])

          this.responseData = res.data[0]; 
       
          this.fileUrl = this.fileImgUrl=  this.responseData.profileImages != ''  && this.responseData.profileImages!='default_user.png' ? this.api.IMAGE_BASE_URL +'/images/'+this.responseData.profileImages : "assets/img/no_image.png";
   
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }

}
