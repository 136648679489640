import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ApiCallingService } from "../../../configs/api-calling.service";
import { NotifierService } from "angular-notifier";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-report-user-detail',
  templateUrl: './report-user-detail.component.html',
  styleUrls: ['./report-user-detail.component.scss']
})
export class ReportUserDetailComponent implements OnInit {

  submitted = false;
  userDetails: any = {};
  responseData: any = [];
  responseReasonData: any = [];
  fileToUpload: File = null;
  public id: string = '';
  public fileUrl: string = '';
  public fileImgUrl: string = '';
  public search = '';

  // pagination var
  itemsPerPage: number = 10;
  totalRecords: number;
  skip: number = 0;
  p: number = 1;



  public title = 'Reported User Detail';
  private readonly notifier: NotifierService;
  constructor(
     private formBuilder: FormBuilder, 
     private router: Router, 
     private spinner: NgxSpinnerService,
     public api: ApiCallingService,
     public notifierService: NotifierService,
     private activatedRoute: ActivatedRoute

  ) {
     this.notifier = notifierService
  }

  ngOnInit(): void {

      this.activatedRoute.params.subscribe(params => {
        this.id = params['id'];
        this.userDetail()
        this.userReortedRason();

      })
  }

  pageChanged(val) {

    this.skip = this.itemsPerPage * val - this.itemsPerPage;
      this.userReortedRason();

  }

  userDetail(){
    let api_url = 'admin/user_detail?_id='+this.id

    this.api.getRequest(api_url, {}).then(
      (res) => { 
        if (res != undefined && (res.code == 200 || res.code == 202)) {

          this.responseData = res.data[0]; 


          this.fileUrl = this.fileImgUrl=  this.responseData.profileImages != ''  && this.responseData.profileImages!='default_user.png' ? this.api.IMAGE_BASE_URL +'/images/'+this.responseData.profileImages : "assets/img/no_image.png";
   
        } else {
          if (res) {
            alert(res.message)
          }
        }
      },
      (err) => {

      }
    );

  }



  userReortedRason() {

    // stop here if form is invalid 
    if (this.search) {
      this.p = 1;
      this.skip = this.itemsPerPage * this.p - this.itemsPerPage;
    }
    let api_url = 'admin/repotedUserReason?skip=' + this.skip + '&limit=' + this.itemsPerPage + '&search=' + this.search+ '&user_id='+this.id;

    this.api.getRequest(api_url, {}).then(
      (res) => {
        if (res != undefined && (res.code == 200 || res.code == 202)) {
          this.totalRecords = res.totalRecords;
          this.responseReasonData = res.data
        } else {
          if (res) {
            alert(res.message)

          }
        }
      },
      (err) => {

      }
    );
  }


}
